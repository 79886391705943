//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Navigation",
  methods: {
    isActive: function (route) {
      return route == this.$route.path ? "nav-item active" : "nav-item";
    }
  }
}
