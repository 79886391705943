
export const state = () => ([
  {
    id: "flitm",
    title: "Flitm - be curious",
    icon: "/img/flitm/flitm.png",
    rating: "4.8",
    downloads: "500k",
    playstore:
      "https://play.google.com/store/apps/details?id=com.caesiumstudio.flitm",
    appstore:
      "https://apps.apple.com/us/app/flitm-be-curious/id6452400407",
    youtube: "",
    images: ["/img/flitm/a.png", "/img/flitm/b.png", "/img/flitm/c.png", "/img/flitm/d.png", "/img/flitm/e.png", "/img/flitm/f.png"],
    shortdesc: "Play and sing Alankars and Ragas with Real Harmonium Sounds and Video Tutorials.",
    description: [{
      heading: "",
      sentence: [
        "Flitm: be curious - A coding app where you can learn any programming languages like HTML, CSS, Javascript, Java, and Python and build your professional software right from your mobile phone. " +
        "Flitm is the only app that provides a fully featured programming environment without requiring a laptop or desktop computer for coding. You can start learning to code right from your mobile phone, anytime and anywhere. ",
        "- Courses designed by experts and professionals",
        "- A proven scientific way to learn coding by engagement",
        "- Freedom to code right from your mobile device",
        "- The fastest way to learn to code and get certified",
      ]
    }, {
      heading: "Features",
      sentence: []
    }, {
      heading: "Learn to code - anytime, anywhere",
      sentence: [
        "If you don’t have a laptop or don’t have time to start learning to code, then Flim is what you need. With Flitm you can start learning any programming language from beginner to advanced and build functional projects and games."
      ]
    }, {
      heading: "Write real code, right from your mobile",
      sentence: [
        "Flitm not only provides you with course content created by experts but also provides an In-App Code Editor that helps you try out new coding ideas or build projects."
      ]
    }, {
      heading: "Job-oriented programming courses",
      sentence: [
        "If you are trying to learn programming because you want to change your career or get a better job, Flitm has courses exactly for that purpose. With Flitm you can practice technical coding questions, learn data structures and algorithms, learn clean code concepts, and also competitive programming."
      ]
    }, {
      heading: "Earn a trusted and verifiable certification",
      sentence: [
        "Learning coding skills is not enough unless you have a trusted certificate to show in your new job. Flitm provides you with an authentic certificate that comes with a verifiable reference code that you can be proud of."
      ]
    }, {
      heading: "Interactive and bite-sized courses",
      sentence: [
        "Learning to code can be difficult if it is too much content to learn. This is why Flitm strategically provides you with courses that are easy and fun to learn and finish before you lose your motivation."
      ]
    }, {
      heading: "Build your dream carrier",
      sentence: [
        "The world runs on Code! No matter which career you are in, learning to code can do miracles in your career. Start learning today and change your life and career."
      ]
    }]
  },
  {
    id: "harmonium",
    title: "Harmonium - Real Sounds",
    icon: "/img/harmonium.png",
    rating: "4.8",
    downloads: "500k",
    playstore:
      "https://play.google.com/store/apps/details?id=com.caesiumgames.harmonium",
    appstore: "https://apps.apple.com/us/app/harmonium-real-sounds-raga/id6451088024",
    youtube: "https://www.youtube.com/embed/y_nKvH6B_Hg",
    shortdesc: "Play and sing Alankars and Ragas with Real Harmonium Sounds and Video Tutorials.",
    description: [{
      heading: "",
      sentence: [
        "Harmonium is the most important instrument for learners of Indian Classical Music." +
        "If you are a vocalist learning Ragas and Alankars or a harmonium enthusiast practicing your harmonium skills then you MUST have this app. ",
        "Anybody who is interested in playing Harmonium and looking for a Harmonium app which provides real harmonium sounds and all octaves then this app is definitely for you as It provides you the experience of a Real Harmonium with authentic sounds. ",
        "This app helps you practice anywhere and anytime while you are practicing singing Ragas or Alankaras or you are just with your friends having fun where you can always give your fingers a go and show your talent. ",
        "As harmonium is widely used in Indian classical music, learning to play Harmonium will certainly help you understand the basics of music theory and further enhance your knowledge of Indian Classical Music. As Harmonium is used in Hindustani music and Carnatic music often, It also helps you tune your ears for Swaras and Shruties as the app has sounds recorded from a real harmonium. ",
        "You can download Raga Melody - Indian Classical Music for learning Alankars, Ragas and to understand various techniques to improve your singing. Raga Melody also provides you all the necessary video Tutorials for learning Harmonium."
      ]
    }, {
      heading: "Features",
      sentence: []
    }, {
      heading: "Slide Your Fingers",
      sentence: [
        "To play different keys, you can just slide your fingers from one key to another and the app plays the next key on the keyboard."
      ]
    }, {
      heading: "Customize for Your Device",
      sentence: [
        "This app is made to fit your device, no matter if you have a large Tablet or a small screen mobile phone. This harmonium is made to fit all screen sizes. "
      ]
    }, {
      heading: "Multiple Key Press Supported",
      sentence: [
        "Harmonium -Real Sounds helps you play any combinations of keys as you just need to press all the keys together. Is it not amazing?"
      ]
    }, {
      heading: "Graphics Effects",
      sentence: [
        "While you play Harmonium and enjoy the Music, a little bit of Eye-Candy is always a good idea. Enjoy the Glitters ;)"
      ]
    }, {
      heading: "Personalize Your Settings",
      sentence: [
        "Once you set Harmonium to fit your preferred zoom level and Octave, the App remembers it so you do not have to configure it every time. "
      ]
    }, {
      heading: "Learning Resources",
      sentence: [
        "If you are beginner and looking for some lessons, you can just play the Video Lessons or Download the Raga Melody - Indian Classical Music app to read. "
      ]
    }]
  }, {
    id: "drive",
    title: "Drive - Traffic Signs and Car Controls",
    icon: "/img/drive.png",
    rating: "4.8",
    downloads: "500k",
    shortdesc: "Learn Traffic Signs, German Vocabulary & Cockpit Controls to get Driving License",
    playstore:
      "https://play.google.com/store/apps/details?id=com.caesiumstudio.fuehrerschein",
    youtube: "https://www.youtube.com/embed/4Gmhr3O3iIA",
    description: [
      {
        heading:
          'Are you excited to soon drive a car and looking for an app to prepare for driving test? Then "Drive" will give you a head start.',
        sentence: [
          '"Drive" provides you vocabulary of all relevant words which you would need to succeed in your German driving license theory and practical exams.',
          "This app also provides you the information about Traffic signs and helps you understand what they mean and what you are supposed to do when you encounter one.",
          "Also, with this App you get to learn and understand cockpit controls and what they mean, even before you sit in the driving seat for the first time.",
          '"Drive" helps you learn traffic signs German if you live in Germany, Austria, Belgium or any other German speaking country.'
        ]
      },
      {
        heading: "German Vocabulary",
        sentence: []
      },
      {
        heading: "Features:",
        sentence: []
      },
      {
        heading: "FLASH CARDS",
        sentence: [
          '"Drive" provides you over 400+ words which you would definitely need to succeed in your driving license exam. You can learn these words with help of beautiful FlashCards, where you also have a picture to help you understand what the word means. '
        ]
      },
      {
        heading: "QUIZ",
        sentence: [
          "You can choose to test your learning and challenge yourself by taking the quiz. Quiz helps you remember the words better and lets you repeat the words which you got wrong. Happy quizzing :) "
        ]
      },
      {
        heading: "RANDOM",
        sentence: [
          "If you are bored, you just switch on the random Vocabulary mode and learn the German vocabulary in random order.."
        ]
      },
      {
        heading: "REPEAT",
        sentence: [
          "Once you have learnt the words, you can always repeat the flash cards in order not to forget them."
        ]
      },
      {
        heading: "Traffic Signs",
        sentence: [
          '"Drive" provides a list of over 260 traffic signs with their explanations. Traffic signs can be remembered easily by playing the traffic sign quiz. You can search traffic signs by name and have a quick look on any traffic sign card. '
        ]
      },
      {
        heading: "FILTER BY CATEGORY OR COLOR",
        sentence: [
          'The traffic signs can be also filtered by category or colors. You can select a category such as "Danger Traffic Signs" and you would see only the danger related traffic signs. This gives you an easy way to access the traffic sign card that you need. Also, the traffic signs can be filtered based on color such as Red, Blue, Yellow or Mixed. If you choose Red, you would see all the prohibition traffic signs whereas Blue shows you only the recommendations traffic signs.'
        ]
      },
      {
        heading: "TRAFFIC SIGN QUIZ",
        sentence: [
          "You can also lookup the traffic signs in the quiz view where you can learn them by playing the traffic sign quiz. You would see a traffic sign where you are give 4 options to identify the traffic sign. You click the correct answer and you get the next traffic quiz card."
        ]
      },
      {
        heading: "Cockpit Controls",
        sentence: []
      },
      {
        heading: "ALL COCKPIT CONTROLS",
        sentence: [
          '"Drive" helps you learn and understand over 100+ cockpit controls which you would definitely need to know, when you want to drive a car. Cockpit controls can be arranged by category or by colors.'
        ]
      }
    ]
  },
  {
    id: "audiowise",
    title: "Audiowise - audiobooks and podcasts",
    icon: "/img/audiowise.png",
    rating: "4.8",
    downloads: "500k",
    linux: "https://github.com/caesiumstudio/audiowise-app",
    macos:
      "https://github.com/caesiumstudio/audiowise-app/releases/tag/latest",
    appstore:
      "https://apps.apple.com/app/audiowise-audiobooks-podcast/id6621180222",
    playstore:
      "https://play.google.com/store/apps/details?id=com.jauntymarble.game",
    youtube: "",
    images: ["/img/audiowise/1.png", "/img/audiowise/2.png", "/img/audiowise/3.png", "/img/audiowise/4.png", "/img/audiowise/ipad1.png", "/img/audiowise/ipad2.png"],
    shortdesc: "Download and listen to offline YouTube videos, podcast, audiobooks & white noise  ",
    description: [
      {
        heading: "",
        sentence: [
          "Audiowise - audiobooks and podcast, provides you with the most convenient way to listen to your audiobooks and podcasts while you are on the go. ",
          "No matter what you want to listen to, the ability to download YouTube videos, podcasts, audiobooks, and white noise as offline MP3 for offline use is a game-changer in how you manage and enjoy your media.",
        ]
      },
      {
        heading: "APP FEATURES",
        sentence: [
        ]
      },
      {
        heading: "ULTIMATE STOP AND RESUME",
        sentence: [
          "Whether commuting, traveling, or simply seeking a break from screen time, you can always download and listen to your favorite YouTube videos, podcasts, and audiobooks from exactly the point where you left off"
        ]
      },
      {
        heading: "Your own YouTube to mp3 converter free",
        sentence: [
          "Audiowise is your own YouTube to mp3 or mp4 convert. You can download any YouTube video as audio and convert them to Mp3 or Mp4 format and listen to them offline."
        ]
      },
      {
        heading: "Download Youtube to mp3 or Youtube to mp4 audios",
        sentence: [
          "With Audiowise desktop application you can download audiobooks and convert them from Youtube to mp3 or mp4 format. Then you can just add them to Audiowise mobile app and listen to them offline."
        ]
      },
      {
        heading: "Listen to free audio books",
        sentence: [
          "With Audiowise you can download audiobooks from YouTube and listen to the free audiobooks online and offline. If you already have some audiobooks in mp3 or wav format, you can add them to Audiowise and listen to the free audiobooks."
        ]
      },
      {
        heading: "PRACTICE LANGUAGE LISTENING SKILLS",
        sentence: [
          "Learning a new language and want to improve your listening skills? Then listen to all your audio language lessons with this app."
        ]
      },
      {
        heading: "FINISH ALL YOUR AUDIOBOOKS",
        sentence: [
          "You can listen to your audiobooks that you've been meaning to finish for a long time -  without worrying about internet connectivity. "
        ]
      },
      {
        heading: "LISTEN TO PODCAST EPISODES",
        sentence: [
          "Podcasts offer a world of knowledge, inspiration, and entertainment, and having them at your fingertips offline allows you to enjoy them during a long flight, a road trip, or even in areas with limited network coverage without ads."
        ]
      },
      {
        heading: "LEARN NEW SKILLS WHILE YOU WORK",
        sentence: [
          "With offline downloads, you can enjoy your favorite novels, biographies, or self-help books without the need for an internet connection.  Start your literary journey during a workout, while cooking, or as you unwind before bed."
        ]
      },
      {
        heading: "SLEEP TIME CALMING SOUNDS",
        sentence: [
          "White noise is a powerful tool for enhancing focus, relaxation, and sleep. Downloading white noise tracks offline gives you the ability to create a calming atmosphere anytime, anywhere. ",
          "Start taking control of your digital content consumption today. Download and listen offline to stay entertained, informed, and relaxed no matter where life takes you."
        ]
      }
    ]
  },
  {
    id: "jal-tarang",
    title: "Jal Tarang",
    icon: "/img/jaltarang.png",
    rating: "4.8",
    downloads: "500k",
    playstore:
      "https://play.google.com/store/apps/details?id=com.caesiumstudio.jaltarang",
    youtube: "https://www.youtube.com/embed/xoLxzGyENrY",
    shortdesc: "Play and learn ragas and songs with Indian musical instrument - Jal Tarang",
    description: [
      {
        heading: "",
        sentence: [
          "The Jal Tarang is a melodic percussion instrument which originates from the Indian subcontinent. It is one of the most rarely heard instruments today but is one of the oldest instrument in the world.",
          "You can play any Indian Raga, Alankar Saralie or light melodies as well. The magic of Jal Tarang comes to life by ceramic or metal bowls filled with water which can be tuned to any desired frequency by varying the quantity of water in it."
        ]
      },
      {
        heading: "Extremely Sensitive Touch",
        sentence: [
          "This app provides the most sensitive touch for striking the bowl accurately to produce a perfect melody."
        ]
      },
      {
        heading: "Indian Ragas",
        sentence: [
          "The app provide various configurations of notes based on various Indian Ragas such as – Raga Durga, Raga Bhairav, Raga Shivaranjini and Raga Bhupali. Also you can play simple Saragam on the standard notes. "
        ]
      },
      {
        heading: "Beautiful Graphics",
        sentence: [
          "The app provides, a beautiful environment for the musical instrument to you so that you can enjoy playing the instrument to the fullest extent. "
        ]
      },
      {
        heading: "Video Lessons",
        sentence: [
          "You can learn how to play Jal Tarang from within the app by clicking on the Video lessons button inside the app. "
        ]
      }
    ]
  },
  {
    id: "tabla",
    title: "Tabla",
    icon: "/img/tabla.png",
    rating: "4.8",
    downloads: "500k",
    playstore:
      "https://play.google.com/store/apps/details?id=com.caesiumstudio.tabla",
    appstore: "https://apps.apple.com/us/app/tabla-classical-drums/id6451099152",
    youtube: "https://www.youtube.com/embed/OuW2TEKtpHs",
    shortdesc: "Tabla - Indian Drum is music instrument like dholak or mridangam to play taals",
    description: [
      {
        heading: "",
        sentence: [
          "Tabla – Indian Drums with Real Sounds, is the most important percussion instrument of Indian Classical music and it is played along with Sitar, Sarod and Harmonium as support Instruments. If you have always wondered and dreamt of learning to play Tabla, then this app has got you covered.",
          "Unlike other Tabla Apps, this app gives you the experience of playing a real Tabla as it has real Tabla Sounds and provides various Tabla configurations for both Tabla drums (Syahi). ",
          "This app helps you practice Tabla anywhere and anytime while you are practicing your Bols/Taals or having fun with your friends. With Tabla – Indian Drums app, you can always give your fingers a go and show your talent.",
          "As Tabla is a percussion instrument, it will definitely help you tune your ears to identify the beats and improve your sense of music. In Addition, with this app you can also practice various Thekas, Taals, Bols and Rhythms. ",
          "Tabla – Indian Drums with Real Sounds, gives you freedom to learn and improve your music skills and for a deeper understanding of various Ragas and Alankars you can also Download Raga Melody – Indian Classical Music or Harmonium - Real Sounds.",
          "Tabla – Indian Drums with Real Sounds, gives you freedom to learn and improve your music skills and for a deeper understanding of various Ragas and Alankars you can also Download Raga Melody – Indian Classical Music or Harmonium - Real Sounds."
        ]
      },
      {
        heading: "Supported Strokes",
        sentence: [
          "Ghe, Dha, Dhin, Ka or Kath on Bayan, Ta, Na, Te and Tun on Dayan"
        ]
      },
      {
        heading: "Supported Taals:",
        sentence: [
          "Tintal, Jhoomra, Tilwada, Dhamar, Ektal, Jhaptal, Keherwa, Rupak, Dadra"
        ]
      },
      {
        heading: "",
        sentence: []
      },
      {
        heading: "FEATURES",
        sentence: [""]
      },
      {
        heading: "Real Tabla Graphics",
        sentence: [
          "This app gives you the experience of Real Tabla by the realistic graphics of Tabla Heads which provide visual feedback of strokes. When you hit the Tabla head, it scales up. "
        ]
      },
      {
        heading: "Real Sounds",
        sentence: [
          "This Tabla app has real recorded sounds of Real Tabla strokes so that you get the real sense of how a Tabla sounds. Playing Tabla on this app, also prepares your ears for playing the real Tabla."
        ]
      },
      {
        heading: "Audio Recording",
        sentence: [
          "You can record audio when you are playing Tabla and share it with your friends. The recording can be done in two modes, high quality(wav) and low quality (aac) which provides you sound suitable for your purpose."
        ]
      },
      {
        heading: "Sound Effects",
        sentence: [
          "You can also mix your Tabla playing with various sound effects. During the video lessons, you would learn how to include these sounds effects into your table strokes. "
        ]
      },
      {
        heading: "Play with Loops",
        sentence: [
          "You would definitely enjoy playing Tabla into a concert like setting, where other instruments are supporting you in your Tabla performance. Loops are exactly for that purpose. They give you a rhythm where you can practice your concert."
        ]
      },
      {
        heading: "Video Lessons",
        sentence: [
          "To give you a jump start, this app brings to you some beginner to intermediate Tabla Lessons where you can practice basic strokes and some Taals as well. "
        ]
      },
      {
        heading: "Different Configurations",
        sentence: [
          "In order to play different strokes for different Taals and Thekas, this app allows you to configure which sounds are to be played when you stroke the Tabla head. You can configure the Syahi on both heads. "
        ]
      }
    ]
  },
  {
    id: "harmonium-lite",
    title: "Harmonium Lite",
    icon: "/img/harmonium-lite.png",
    rating: "4.8",
    downloads: "500k",
    playstore:
      "https://play.google.com/store/apps/details?id=com.caesiumstudio.harmoniumlite",
    youtube: "https://www.youtube.com/embed/y_nKvH6B_Hg",
    shortdesc: "Harmonium App with High Quality Sounds and Beautiful Graphics",
    description: [
      {
        heading: "",
        sentence: [
          "Harmonium Lite is the lite version of Harmonium - Real Sounds.",
          "If you are looking for an app which helps you learn and practice your Harmonium playing skills and helps you riyaz with Ragas, Harmonium Lite is the app for you.",
          "Harmonium Lite gives you the perfect touch sensitivity to play simple Ragas of Indian Classical Music and you can also create music of your choice. "
        ]
      },
      {
        heading: "Extremely Sensitive Touch",
        sentence: [
          "Touch Sensitive - The lite version of Harmonium - Real Sounds provides perfect touch sensitivity so that you can enjoy playing the music. "
        ]
      },
      {
        heading: "Multi-Touch",
        sentence: [
          "Harmonium Lite version provides a much better experience for multi-touch support. "
        ]
      },
      {
        heading: "Re-Size Keys",
        sentence: [
          "You can resize the keyboard based on how big your device is and how big your fingers are. It also helps adults as well as children to comfortably play the Harmonium."
        ]
      }
    ]
  },
  {
    id: "finance-calculator",
    title: "Compound Interest Loan Calc",
    icon: "/img/calculator/icon.png",
    rating: "4.8",
    downloads: "500+",
    playstore:
      "https://play.google.com/store/apps/details?id=com.caesiumstudio.deliverthepost",
    appstore:
      "https://apps.apple.com/app/compound-interest-loan-calc/id6451216567",
    youtube: "",
    shortdesc: "A calculator for compound interest calculation with graph and table view.",
    images: ["/img/calculator/fg.png", "/img/calculator/1.png", "/img/calculator/2.png", "/img/calculator/3.png", "/img/calculator/4.png", "/img/calculator/5.png"],
    description: [
      {
        heading: "",
        sentence: [
          "This Compound Interest Calculator is a robust financial tool designed to help you understand and visualize the growth of your investments over time. It calculates both monthly and yearly compound interest, providing you with detailed insights into how your investment will grow. The calculator features interactive graphs and table views, making it easy to analyze and interpret the data."
        ]
      },
      {
        heading: "Features:",
        sentence: []
      },
      {
        heading: "Monthly and Yearly Interest Calculations",
        sentence: [
          "Compound Interest Calculator has the ability to calculate interest on both a monthly and yearly basis. This flexibility allows users to see how different compounding periods affect the growth of their investments. Whether you prefer to see a more granular month-by-month breakdown or a broader yearly overview, our calculator has you covered."
        ]
      },
      {
        heading: "Interactive Graph View",
        sentence: [
          "Visualizing your investment growth is made easy with our interactive graph view. This feature allows you to see the progression of your investment over time, making it simpler to understand the impact of compound interest."
        ]
      },
      {
        heading: "User-Friendly Interface",
        sentence: [
          "The graph is designed with a user-friendly interface, ensuring that even those with minimal financial knowledge can easily interpret the data. The graph's clear, clean design eliminates confusion, making it a powerful tool for all investors."
        ]
      },
      {
        heading: "Detailed Table View",
        sentence: [
          "For those who prefer to see the numbers, the detailed table view presents all the calculated data in a comprehensive format. This feature provides a thorough breakdown of your investment’s growth over time."
        ]
      },
      {
        heading: "Customizable Inputs",
        sentence: [
          "Our Compound Interest Calculator allows you to input a variety of parameters to tailor the calculations to your specific needs.",
          "Initial Investment Amount:Enter the starting amount of your investment.",
          "Interest Rate: Input the annual interest rate, which can be adjusted to see how different rates impact your investment.",
          "Compounding Frequency: Choose how often interest is compounded: monthly, quarterly, or yearly.",
          "Investment Duration:Specify the length of your investment period in months or years.",
          "Additional Contributions:Include regular additional contributions to see how they affect the overall growth of your investment.",
          "Real-Time Results: As you adjust the inputs, the calculator provides real-time results, immediately updating the graph and table views. This instant feedback allows you to quickly see the effects of different variables on your investment."
        ]
      }
    ]
  },
  {
    id: "raga-melody",
    title: "Raga Melody",
    icon: "/img/raga-melody.png",
    rating: "4.8",
    downloads: "500k",
    playstore:
      "https://play.google.com/store/apps/details?id=com.ragamelody.rm",
    appstore: "https://apps.apple.com/us/app/raga-melody-taal-swar-alankar/id6476657451",
    youtube: "https://www.youtube.com/embed/kOpVBJqstuk",
    shortdesc: "App about Hindustani and Carnatic Classical Music Alankars Ragas Vocal Notes etc",
    description: [
      {
        heading:
          "Music is a moral law. It gives soul to the universe, wings to the mind, flight to the imagination, and charm and gaiety to life and to everything. - Plato",
        sentence: [
          "This application provides quality content for learning Indian Classical Music (Hindustani Music and Carnatic Music) including literature and learner notes about Hindustani Classical Music, Carnatic Classical Music, Alankar Saralies, Ragas, Thaats, Saragam, Saptak knowledge and various tips.",
          "The application features content from the online blog: www.ragamelody.com and provides access to all of its content."
        ]
      },
      {
        heading: "The app provides the following content:",
        sentence: []
      },
      {
        heading: "Ragas",
        sentence: [
          "Raga Durga, Raga Bhoop or Bhoopali, Raga Saranga, Raga Bhairavi, Raga Hamir, Raga Kalavati, Raga Kedar, Raga Yaman, Raga Asavari, Raga Bhimpalasi, Raga Bilawal and many more."
        ]
      },
      {
        heading: "Alankar Saralies",
        sentence: [
          "Combinations of notes from Saptak to learn and improve your singing skills"
        ]
      },
      {
        heading: "Blogs and Tips",
        sentence: [
          "Various tips and tricks to improve your singing skills and how to extend your voice range.",
          "For understanding the concepts of Indian classical music, the app provides various researched articles about Thaats, Saragam, Ashta Prahar, Srutis, Instruments such as Harmonium, Flute, Tabla, Mradangam, Sarangi, Sitar and Music theory etc."
        ]
      },
      {
        heading: "Videos for Practice",
        sentence: [
          "We have picked the best video resources to learn Hindustani and Carnatic Music with Vocals from renowned gurus of music world."
        ]
      },
      {
        heading: "Get notified about exciting Articles",
        sentence: [
          "Whenever we release new content, we notify you so that you don’t miss it. We continuously update and discover new content which you would love.",
          "Indian Classical music is an ancient system of Swaras and these Swaras are the basis of all the Ragas and melodies of it."
        ]
      }
    ]
  },
  {
    id: "piano",
    title: "Piano - Real Sounds Keyboard",
    icon: "/img/piano.png",
    rating: "4.8",
    downloads: "500k",
    appstore: "https://apps.apple.com/us/app/piano-real-sounds-keyboard/id6450960532",
    playstore:
      "https://play.google.com/store/apps/details?id=com.caesiumstudio.piano",
    youtube: "https://www.youtube.com/embed/E3aWzkV1G9Q",
    shortdesc: "Learn Piano with 88 keys and 7 octaves of grand piano with virtual lessons.",
    description: [
      {
        heading:
          "Music is a moral law. It gives soul to the universe, wings to the mind, flight to the imagination, and charm and gaiety to life and to everything. - Plato",
        sentence: [
          "Piano is the most important instrument for learners of modern music",
          "If you are looking for a Piano app which provides high touch sensitivity for fast playback or if you are a piano enthusiast, pianist, keyboardist, musician, performer, artist or a beginner practicing your piano skills then you MUST have this app."
        ]
      },
      {
        heading: "The app provides the following content:",
        sentence: [
          "Anybody who is interested in playing piano that provides real piano sounds and all 7 octaves with 88 keys then this app is definitely for you as It provides you the experience of a Real Grand Piano with authentic sounds.",
          "This app helps you practice anywhere and anytime while you are practicing your piano skills or trying to increase the speed of your fingers to impress your friends or just want to give your fingers a go and show your talent.",
          "As Piano is one of the most melodious music instruments, learning to play Piano will certainly help you understand the basics of music theory and further enhance your ability to recognize specific notes and chords."]
      },
      {
        heading: "FEATURES",
        sentence: []
      },
      {
        heading: "Very fast and responsive Piano Keyboard",
        sentence: [
          "This is the FASTEST Piano app you would find on Android. The speed of this app comes from the low level touch events specifically designed for extremely high touch sensitivity apps. "
        ]
      },
      {
        heading: "Amazing Realistic Graphics",
        sentence: [
          "The app gives you the perfect feeling of a real piano. It has amazing graphics, real shadows of pressed and unpressed keys. "
        ]
      },
      {
        heading: "88 keys and 7 Octaves",
        sentence: [
          "Just like a Grand Piano, you can enjoy the full length of the Keyboard with 88 Keys from A0 to C8 which covers all 7 Octaves. "
        ]
      },
      {
        heading: "Dual Mode for Advanced Users",
        sentence: [
          "The Dual mode gives you a profession two keyboard view which you can easily set to different octaves. You wanna play a song with more octaves.. Well there you go"
        ]
      },
      {
        heading: "Twin Mode for Competition or Collaboration",
        sentence: [
          "Twin mode is for you and your partner. Just put the phone on the table and you both can play at the same time. "
        ]
      },
      {
        heading: "Finally a Metronome",
        sentence: [
          "And now you also have a in-app metronome which helps you to synchronize your beats with your fingers. Isn’t that amazing?"
        ]
      },
      {
        heading: "Piano with Original Sounds",
        sentence: [
          "With this app, you do not need to worry about the quality of sounds. This app provides the best sound quality which you can enjoy on your phone or connected to high quality speakers. "
        ]
      }
    ]
  }
]);

export const mutations = {
  increment(state) {
    state.counter++;
  }
};

// const getters = {
//   getAllApps: function (state) {
//     return state.data.apps;
//   }
// };

// export default {
//   state,
//   mutations,
//   getters
// };
